import { Helmet } from "react-helmet";
import PageHeader from "../../common/PageHeader/PageHeader";
import SectionText from "../home/SectionText/SectionText";
import SectionTextTitleContent from "../home/SectionText/SectionTextTitleContent";
import SectionBubble from "./SectionBubble/SectionBubble";
import SectionTab from "./SectionTab/SectionTab";
import TeamForm from "./TeamForm/TeamForm";
import classes from "./TeamPage.scss";

const TeamPage = (props) => {
	const page_summary = "Youth Innovation Lab constitutes a young team from diverse discipline, ethnicity, and geographical areas with the experiences from local, national and international arena in the field of Research, Creative Design, and Developers.";
	// const about_us_section = {
	// 	"prefix": "ABOUT US",
	// 	"body": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat"
	// };
	const bubble_section = {
		"prefix": "KNOW ABOUT",
		'title': "Resource allocation"
	};
	const tab_section = {
		"prefix": "GET TO KNOW &",
		'title': "Meet the creative group of Youth",
		"body": "YI-Lab has a diverse and competitive team—both in research and technology with an in-depth understanding of local, national, regional, and global priorities of the Government of Nepal. We have a multidisciplinary team with expertise ranging from professionals in the sector of digital mapping, geo-information management, DRR experts, remote sensing, social scientist, data visualization, computer engineers and sectorial experts in disaster risk, climate change, human rights, and alike.",
	};

	return (
		<>
			<Helmet>
				<title>YI-LAB | TEAM </title>
				<meta name="title" content="YOUTH INNOVATION LAB TEAM"/>
				<meta name="description" content="YI-Lab has a diverse and competitive team—both in research and technology with an in-depth understanding of local, national, regional, and global priorities of the Government of Nepal. We have a multidisciplinary team with expertise ranging from professionals in the sector of digital mapping, geo-information management, DRR experts, remote sensing, social scientist, data visualization, computer engineers and sectorial experts in disaster risk, climate change, human rights, and alike."/>
				<meta name="keyword" content="youth, innovation, lab, team, research and technology, multidisciplinary, geo-information, computer engineers, human rights, social scientist, DRR experts, digital mapping, engineers"/>
			</Helmet>

			<PageHeader title="Multi-disciplinary Team of Youth" summary={page_summary}></PageHeader>

			{/* About us section  */}
			{/* <SectionText contents={about_us_section}></SectionText> */}

			{/* Meet createive group section  */}
			<SectionText contents={bubble_section}>
			</SectionText>
			<>
				<SectionBubble></SectionBubble>
			</>

			{/* Tab Section  */}
			<section className="creative_group_section pt-1 pb-5">
				<SectionText contents={tab_section}></SectionText>
				<SectionTab className="intra_section_margin_top"></SectionTab>
			</section>
			
			{/* Meet Creative group section  */}
			<div className="container-fluid yilab-container page_margin_left section_margin_top" id="get_in_touch">
				<div className="row join-team-row">
					<div className="col-md-5">
						<SectionTextTitleContent
							title="Leave your Detail"
							body="Fill up the form with your details and leave your comments if you are interested to work with us."
							is_multi_col={true}
						>
						</SectionTextTitleContent>

					</div>
					<div className="col-md-7 team-form-wrapper mt-5 mt-md-0">
						<SectionTextTitleContent title="Get in touch with us."></SectionTextTitleContent>
						<TeamForm />
					</div>
				</div>
			</div>

		</>
	);
}

export default TeamPage;