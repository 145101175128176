import HtmlParser from 'react-html-parser';
import LinkWithArrow from '../../../common/LinkWithArrow/LinkWithArrow';
import classes from './SectionTabItem.scss';
const SectionTabItem = (props) => {
	const item = props.item;
	const members = props.item.members ?? [];

	return (
		(members.length > 0) ?
			<div className={"row row-eq-height mt-4 g-5"}>
				{members.map((member, index) =>
					<>
						<div className={"col-md-6 mb-4 order-1 order-md-0"} key={member.id}>
							<div className="tab_item_wrapper position-relative h-100" style={{ backgroundImage: `URL(${member.image})` }}>
								{/* <img src="/images/news.jpg"/> */}
								<div className="tab_item_desc_wrap p-4">
									<small>{member.position}</small>
									<p className="h4">{member.name}</p>
									<div className="team_opacity_wrap">
										<p className="tab_item_desc mb-4">
											{HtmlParser(member.description.replace(/(<([^>]+)>)/gi, ""))}
										</p>
										<LinkWithArrow link_to={"/team/" + member.slug} link_text="Know More" className="normal-effect"></LinkWithArrow>
									</div>
								</div>
							</div>
						</div>

						{((index == 0) && (item.description)) ?
							<div className="col-md-6 order-0">
								<div className="h-100">
									<img className='w-100' src={item.image} />
									<div className="tab_item_desc_wrap py-4">
										<p className="h4">About The Team</p>
										<p className="tab_item_desc">
											{HtmlParser(item.description)}
										</p>
									</div>
								</div>
							</div>
							:
							''}
					</>
				)}
			</div>
			: ""


	);
};

export default SectionTabItem;