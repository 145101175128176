import { useState, useEffect } from 'react';
import {Tabs,Tab} from 'react-bootstrap';
import Loading from '../../../common/Loading/Loading';
import SectionTabItem from './SectionTabItem';
const SectionTab = (props) => {

	const [error, setError] = useState(null);
	const [items, setItems] = useState([]);
	const [allMembers, setAllMembers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [activeKey, setActiveyKey] = useState('all');

	const urls = [
		process.env.REACT_APP_API_BASE_URL + '/team-member',
		process.env.REACT_APP_API_BASE_URL + '/team?expand=true&ordering=id',
	];

	useEffect(() =>{
		Promise.all(
			[
				fetch(urls[0])
				.then((res)=> res.json())
				.then(
					(result) => {
						return result;
					}
				),

				fetch(urls[1])
				.then((res)=> res.json())
				.then(
					(result) => {
						return result;
					}
				),
			]
		).then(
			([allMemberRes, teamRes])=>{
				setItems(teamRes.results);
				setAllMembers(allMemberRes.results);
				setIsLoading(false);
			},
			(error) => {
				setError(error);
			}
		)

	}
	,[]);



	if( isLoading ){
		return <Loading />
	}
		
	const handleSelect = (key) => {
		setActiveyKey(key);
	};

	return (
		<section className={"section_tab "+props.className??''}>
			<div className="container-fluid yilab-container">
					
				<Tabs defaultActiveKey={activeKey} id="uncontrolled-tab-example" className="mb-3" onSelect={handleSelect}>
					<Tab eventKey="all" title="All">
						<SectionTabItem count={2} key="all_members_key" item={{members: allMembers}}></SectionTabItem>
					</Tab>

					{items.map((item,index)=>
						<Tab eventKey={"key"+item.id} title={item.title} key={item.id}>
							<SectionTabItem count={index} key={"stkey"+item.id} item={item}></SectionTabItem>
						</Tab>
					)}	
				
				</Tabs>

			</div>
		</section>
	);
};

export default SectionTab;